// Components
import { Text, Button } from "@mobi/ds"
import Img from "components/Img"

//Style
import "./index.scss"

//Hooks
import useMake from "hooks/useMake"

const Marketplace = ({ cardItems, currentStep, ga = false }) => {
  const { onElementClicked } = useMake()

  const prepareToTrack = (title, button) => {
    const webElement = {
      elementType: "button",
      location: "Marketplace",
      name: title,
      text: button,
    }
    onElementClicked(webElement)

    if (ga) {
      ga("navigation_click", {
        flow: "financing rv",
        location: currentStep === "simulation" ? "step 5" : "step 6",
        section: title,
        text: button,
      })
    }
  }

  return (
    <div className="marketplace">
      <Text className="marketplace__title" as="h2" size="lg" weight="800">
        achamos que pode te interessar
      </Text>

      <div className="marketplace__wrapper">
        {cardItems.map(({ file, url, title, paragraph, button }, key) => {
          return (
            <div className="marketplace__card" key={key}>
              <div className="marketplace__image">
                <Img file={file} />
              </div>
              <div className="marketplace__content">
                <div className="marketplace__wrapper-text">
                  <Text as="h3" size="xs" weight={700}>
                    {title}
                  </Text>
                  <Text className="marketplace__paragraph" size="sm">
                    {paragraph}
                  </Text>
                </div>
                <Button
                  tagName="button"
                  target="_blank"
                  role="button"
                  onClick={() => {
                    prepareToTrack(title, button)
                    window.open(url, "_blank")
                  }}
                >
                  {button}
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Marketplace
