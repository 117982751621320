import { HOMEPATH } from "gatsby-env-variables"

// Components
import { Footer, Text, Button } from "@mobi/ds"
import { Likert } from "@vehicles/components"
import Layout from "components/Layout"
import Header from "components/Header"
import If from "@mobi/components/If"
import Marketplace from "components/Marketplace"

//Hooks
import useMake from "hooks/useMake"

// Tracking
import analytics from "@mobi/libraries/analytics"

// Utils
import { getUrlParamAll } from "utils/browser"
import isMobile from "@mobi/utils/browser/isMobile"

//Data
import {
  marketplaceUnapprovedCardItems,
  marketplaceCardItems,
} from "./dataContent"

// Style
import "./index.scss"

const Failed = () => {
  const { error } = getUrlParamAll()
  const { onElementClicked } = useMake()

  return (
    <Layout className="--simulationpage --errorpage" title="Resultado">
      <Header
        prevUrl={HOMEPATH}
        dynamicLogo={"itau"}
        linkLogoUrl={HOMEPATH}
        isActiveButtonAccess={false}
        mobileInfosApp={false}
      />
      <div className="denied-proposal">
        <div className="denied-proposal__form-default">
          {templates[error]
            ? templates[error](onElementClicked)
            : templates[1](onElementClicked)}
        </div>
        <br aria-hidden="true" />
        <Footer
          links={{
            urlHelpPage: "https://www.itau.com.br/atendimento-itau/para-voce",
          }}
        />
      </div>
    </Layout>
  )
}

const templates = {
  1: (onElementClicked) => {
    const prepareToMakeTrack = () => {
      const webElement = {
        elementType: "button",
        location: "simulation",
        name: "EntendaComoFunciona",
        text: "entenda como funciona",
      }

      onElementClicked(webElement)
    }

    const goTo = () => {
      document.querySelector(".knowhowworks").scrollIntoView({
        behavior: "smooth",
      })
    }

    return (
      <>
        <div className="denied-proposal__header">
          <div className="denied-proposal__content">
            <div className="denied-proposal__holder">
              <Text as="h1" size="xxl">
                <strong>
                  poxa, infelizmente não conseguimos aprovar seu financiamento
                  nesse momento
                </strong>
              </Text>
              <br aria-hidden="true" />
              <Text size="md">
                Mas não se preocupe, confira algumas dicas abaixo para aumentar
                as suas chance de aprovação
              </Text>
              <br aria-hidden="true" />
              <br aria-hidden="true" />
              <Button
                fluid
                type="button"
                variant="secondary"
                onClick={() => {
                  goTo()
                  prepareToMakeTrack()
                  analytics.track("buttonClickGa", {
                    eventName: "EntendaComoFunciona",
                  })
                }}
              >
                entenda como funciona
              </Button>
            </div>
            <div className="denied-proposal__holder">
              <If condition={!isMobile} renderIf={<Likert />} />
            </div>
          </div>
        </div>
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        <div className="denied-proposal__container knowhowworks">
          <div className="denied-proposal__holder">
            <If condition={isMobile} renderIf={<Likert />} />
            <br aria-hidden="true" />
            <Text as="h2" size="lg">
              <strong> entenda como funciona a análise de crédito</strong>
            </Text>
            <br aria-hidden="true" />
            <Text size="md">
              Avaliamos seus dados com informações disponíveis em cadastros
              públicos, orgãos de proteção ao crédito, Cadastro Positivo, e
              também seus pagamentos em dia e renda.
            </Text>
            <br aria-hidden="true" />
            <br aria-hidden="true" />
            <Text as="h2" size="lg">
              <strong>
                o que fazer para aumentar minha chance de aprovação?
              </strong>
            </Text>
            <br aria-hidden="true" />
            <Text size="md">Algumas dicas que podem ajudar:</Text>
            <ul className="denied-proposal__list-default">
              <li className="item">
                <Text size="md">
                  Ative seu Cadastro Positivo para nos fornecer mais informações
                  sobre você;
                </Text>
              </li>
              <li className="item">
                <Text size="md">
                  Consulte a Receita Federal para ver se existe alguma pendência
                  no seu CPF;
                </Text>
              </li>
              <li className="item">
                <Text size="md">
                  Verifique se não existem renegociações de dívidas em andamento
                  ou mesmo contas atrasadas com bancos ou no mercado.
                </Text>
              </li>
            </ul>
            <br aria-hidden="true" />
            <br aria-hidden="true" />
            <Text as="h2" size="lg">
              <strong>porque pedimos o seu número de celular</strong>
            </Text>
            <br aria-hidden="true" />
            <Text size="md">
              Caso conseguirmos aprovar o financiamento no futuro, podemos
              entrar em contato para ajudar você a acelerar o sonho de ter esse
              carro!
            </Text>
            <br aria-hidden="true" />
            <br aria-hidden="true" />
            <br aria-hidden="true" />
          </div>
          <Marketplace cardItems={marketplaceUnapprovedCardItems} />
        </div>
        <br aria-hidden="true" />
        <br aria-hidden="true" />
      </>
    )
  },
  2: () => {
    return (
      <>
        <div className="denied-proposal__header">
          <div className="denied-proposal__content">
            <div className="simulation-failed__holder">
              <Text as="h2" size="xxl">
                <strong>
                  desculpe, neste momento não conseguimos gerar uma simulação
                </strong>
              </Text>
              <br aria-hidden="true" />
              <Text size="md">
                Fique tranquilo(a)! Usando as informações fornecidas vamos
                tentar fazer a simulação novamente e enviaremos um e-mail :)
              </Text>
            </div>
            <div className="simulation-failed__holder">
              <If condition={!isMobile} renderIf={<Likert />} />
            </div>
          </div>
        </div>
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        <div className="denied-proposal__container">
          <If condition={isMobile} renderIf={<Likert />} />
        </div>
        <div className="denied-proposal__container">
          <br aria-hidden="true" />
          <Text as="h2" size="lg">
            <strong>porque pedimos o seu número de celular</strong>
          </Text>
          <br aria-hidden="true" />
          <Text size="md">
            Caso conseguirmos aprovar o financiamento no futuro, podemos entrar
            em contato para ajudar você a acelerar o sonho de ter esse carro!
          </Text>
        </div>
        <br aria-hidden="true" />
        <div className="denied-proposal__container">
          <br aria-hidden="true" />
          <Text as="h2" size="lg">
            <strong> entenda como funciona a análise de crédito</strong>
          </Text>
          <br aria-hidden="true" />
          <Text size="md">
            Avaliamos seus dados com informações disponíveis em cadastros
            públicos, orgãos de proteção ao crédito, Cadastro Positivo, e também
            seus pagamentos em dia e renda.
          </Text>
          <Marketplace cardItems={marketplaceCardItems} />
        </div>
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        <br aria-hidden="true" />
      </>
    )
  },
}

export default Failed
